import React from "react"

import { graphql } from "gatsby"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "../components/seo"
import PhotoGallery from "../components/photo-gallery"
import Bio from "gatsby-theme-blog/src/components/bio"

const PhotoGalleryPage = ({ data }) => (
  <Layout
    location={{ pathname: "photo-gallery" }}
    title={data.site.siteMetadata.title}
  >
    <SEO title="Photo Gallery" />
    <PhotoGallery images={data.images.nodes} />
    <Bio></Bio>
  </Layout>
)

export default PhotoGalleryPage
export const pageQuery = graphql`
  fragment GalleryFile on File {
    name
    publicURL
  }

  query PhotoGalleryPageImages {
    site {
      siteMetadata {
        title
      }
    }
    images: allFile(
      filter: { extension: { eq: "jpeg" }, name: { regex: "/^(?!.*blooper).*$/" } }
    ) {
      nodes {
        ...GalleryFile
        childImageSharp {
          fixed(width: 1152) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
